















import Vue from "vue";
import { catVue } from "@/util/logging";

//import store from "@/store";

   // eslint-disable-next-line

   export default Vue.extend({
     name: "Statistiken",

     data() {
       return {
         bis: "", // Daten werden unten befüllt …
         von: "",
       };
     },

     components: {
       DatumsbereichAuswahl: () =>
         import(
           /* webpackChunkName: "datumsbereich-auswahl" */ "@/components/util/DatumsbereichAuswahl.vue"
         ),
     },

     methods: {
       onAuswahl(value: { von: string; bis: string }) {
         catVue.info("onAuswahl mit " + JSON.stringify(value)); // someValue
         this.bis = value.bis;
         this.von = value.von;
       },
     },
   });

   //  store.commit("changeAppLoadingState", true, {root: true});
   //  window.setTimeout( () =>   store.commit("changeAppLoadingState", false, {root: true}), 10000)
